import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  atomDark as dark,
  base16AteliersulphurpoolLight as light,
} from "react-syntax-highlighter/dist/esm/styles/prism";

export const TicTacToeEmptyBoard = () => {
  const board = [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="grid grid-cols-3 w-60 h-60">
        {board.map((row, rowIndex) =>
          row.map((_, colIndex) => {
            const isBottom = rowIndex < 2; // Add bottom border if not last row
            const isRight = colIndex < 2; // Add right border if not last column

            return (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={`w-20 h-20 flex items-center justify-center ${
                  isBottom ? "border-b-4" : ""
                } ${isRight ? "border-r-4" : ""} border-gray-500`}
              ></div>
            );
          })
        )}
      </div>
    </div>
  );
};

export const TicTacToeGameView = ({ gameData, setShowWinner }) => {
  const moves = JSON.parse(gameData.moves);
  const [currentMove, setCurrentMove] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);
  const board = moves[currentMove];

  useEffect(() => {
    if (currentMove < moves.length && showAnimation) {
      const timeout = setTimeout(() => {
        if (currentMove < moves.length - 1) {
          setCurrentMove(currentMove + 1);
        } else {
          setShowAnimation(false);
          setShowWinner(true);
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [currentMove, showAnimation]);

  return (
    <div className="flex flex-col items-center">
      <div className="grid grid-cols-3 gap-1 w-32 h-32">
        {board.map((row, rowIndex) =>
          row.map((cell, colIndex) => (
            <div
              key={`${rowIndex}-${colIndex}`}
              className="w-10 h-10 flex items-center justify-center border border-gray-500 text-xl font-bold"
            >
              {cell === 1 ? "X" : cell === 2 ? "O" : ""}
            </div>
          ))
        )}
      </div>
      {!showAnimation && (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setCurrentMove(currentMove - 1)}
            disabled={currentMove === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setCurrentMove(currentMove + 1)}
            disabled={currentMove === moves.length - 1}
          >
            Next
          </button>
        </div>
      )}
      {currentMove < moves.length - 1 ? (
        <button
          className="px-4 py-2 mt-4 bg-gray-300 rounded"
          onClick={() => setShowAnimation(!showAnimation)}
        >
          {showAnimation ? "Pause Autoplay" : "Autoplay"}
        </button>
      ) : (
        <button
          className="px-4 py-2 mt-4 bg-gray-300 rounded"
          onClick={() => setCurrentMove(0)}
        >
          Replay
        </button>
      )}
    </div>
  );
};

export const TicTacToeVisualMap = {
  1: <span className="text-red-500 font-bold">X</span>,
  2: <span className="text-blue-500 font-bold">O</span>,
};

export const TicTacToeDescription = `Tic-Tac-Toe! Get three in a row horizontally, vertically, or diagonally to win.`;

export const TicTacToeProblemStatement = () => {
  const gameStateExample = `gameState = [
          [1, 0, 2],
          [0, 1, 2],
          [0, 0, 0],
    ]`;

  const gameStateAfterMove = `gameState = [
          [1, 0, 2],
          [0, 1, 2],
          [0, 0, 1],
    ]`;

  const isDarkMode = document.documentElement.classList.contains("dark");

  return (
    <div className="space-y-4">
      <p>
        You must implement a class with a method makeMove() that takes in a
        board and returns a move.
      </p>
      <p>
        A gameState is represented as a 3 row by 3 column 2d array of integers,
        where 1&apos;s and 2&apos;s represent player1 (Os) and player2 (Xs) and
        0&apos;s represent an empty slot.
      </p>
      <p>Here is an example:</p>

      {isDarkMode ? (
        <div style={{ fontSize: "16px" }}>
          <SyntaxHighlighter
            language="python"
            style={isDarkMode ? dark : light}
          >
            {gameStateExample}
          </SyntaxHighlighter>
        </div>
      ) : (
        <SyntaxHighlighter language="python">
          {gameStateExample}
        </SyntaxHighlighter>
      )}
      <p>
        Your makeMove() function returns a row [0,2] and column [0,2] to place a
        piece. So if player1 chooses row 2 and column 2, then the gameState
        becomes:
      </p>
      {isDarkMode ? (
        <div style={{ fontSize: "16px" }}>
          <SyntaxHighlighter
            language="python"
            style={isDarkMode ? dark : light}
          >
            {gameStateAfterMove}
          </SyntaxHighlighter>
        </div>
      ) : (
        <SyntaxHighlighter language="python">
          {gameStateAfterMove}
        </SyntaxHighlighter>
      )}
      <p>And player1 wins the game!</p>
      <section className="pt-4 pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          3rd party imports:
        </h2>
        <div className="whitespace-pre-wrap">
          {isDarkMode ? (
            <div style={{ fontSize: "16px" }}>
              <SyntaxHighlighter
                language="python"
                style={isDarkMode ? dark : light}
              >
                numpy, torch
              </SyntaxHighlighter>
            </div>
          ) : (
            <SyntaxHighlighter language="python">
              numpy, torch
            </SyntaxHighlighter>
          )}
        </div>
      </section>
      <section className="pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          Constraints:
        </h2>
        <div className="whitespace-pre-wrap">
          <ul className="pl-5 list-disc">
            <li>Time to instantiate class: 2 seconds</li>
            <li>Time per move: 2 seconds</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export const TicTacToeStarterCode = `import random

# Fill in the makeMove function
class Player:
  
  def __init__(self, playerOne: bool):
    pass

  def makeMove(self, gameState: list[list[int]]) -> list[int]:
    moves = [[i, j] for i in range(3) for j in range(3)]
    valid_moves = []
    
    for x, y in moves:
      if gameState[x][y] == 0:
        valid_moves.append([x, y])
    
    return random.choice(valid_moves)

`;

export const TicTacToeGameCode = `# the tictactoe class that will be used to judge your code
  class Game:
    
    @staticmethod
    def detect_win(game_state, turn):
        rows, cols = 3, 3
        
        # Check rows and columns
        for i in range(rows):
            if all(game_state[i][j] == turn for j in range(cols)) or \
               all(game_state[j][i] == turn for j in range(rows)):
                return turn
        
        # Check diagonals
        if all(game_state[i][i] == turn for i in range(rows)) or \
           all(game_state[i][rows - 1 - i] == turn for i in range(rows)):
            return turn
        
        return 0
    
    @staticmethod
    def legal_moves(game_state, turn):
        if Game.detect_win(game_state, turn) != 0:
            return []
        
        moves = [(i, j) for i in range(3) for j in range(3) if game_state[i][j] == 0]
        return moves
    
    @staticmethod
    def make_move(game_state, turn, move):
        x, y = move
        if 0 <= x < 3 and 0 <= y < 3 and game_state[x][y] == 0:
            game_state[x][y] = turn
        
        return game_state
    
    @staticmethod
    def decode_game_state(encoded_state):
        return eval(encoded_state)
    
    @staticmethod
    def encode_game_state(game_state):
        return str(game_state)

  `;
