import React, { useState, useEffect } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./Auth/FirebaseAuth";
import Header from "./Components/Header";
import About from "./Pages/About";
import PinataChallenge from "./Constants/PinataChallenge";
import HackMidwest from "./Constants/HackMidwest";
import Profile from "./Pages/Profile";
import ProtectedRoute from "./Auth/ProtectedRoute";
import Editor from "./Pages/Editor";
import { getUserIcon } from "./API/User";
import GameArena from "./Pages/GameArena";
import Home from "./Pages/Home";
import Leaderboard from "./Pages/Leaderboard";
import User from "./Pages/User";
import BotDetails from "./Pages/BotDetails";
import ScrollToTop from "./Components/ScrollToTop";

const App = () => {
  const [user, loading, error] = useAuthState(auth);

  const [userIcon, setUserIcon] = useState(0);

  useEffect(() => {
    if (loading) return;
    if (user) {
      getUserIcon(user.uid)
        .then((res) => {
          setUserIcon(res.icon);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user, loading]);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Header user={user} icon={userIcon} />
        {/* Auth errors */}
        {error && (
          <div
            className="p-8 text-sm text-red-800 rounded-lg bg-red-50"
            role="alert"
          >
            <span className="font-medium">Error!</span> There was an issue with
            authentication.
          </div>
        )}
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="*" element={<Navigate to="/games" />} />
          <Route path="/games" element={<Home />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route
            path="/arena/:game"
            element={
              <ProtectedRoute>
                <GameArena user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile user={user} />
              </ProtectedRoute>
            }
          />
          <Route path="/profile/:username" element={<User user={user} />} />
          <Route
            path="/bots/:game/:botId"
            element={
              <ProtectedRoute>
                <BotDetails user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/:game/:modelId?"
            element={
              <ProtectedRoute>
                <Editor user={user} />
              </ProtectedRoute>
            }
          />
          {/* TODO: Change so routes to contest details and populates by id */}
          <Route path="/contests/pinata2024" element={<PinataChallenge />} />
          <Route path="/contests/hackmidwest2024" element={<HackMidwest />} />
          {/* **************************************************************** */}
        </Routes>
      </Router>
    </>
  );
};

export default App;
