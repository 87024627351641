import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";

const SubmissionCode = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="relative w-full overflow-auto px-4 py-1 rounded-lg border-[1px] border-border-light bg-surfaceContain border-border">
      {/* Copy Button */}
      <div className="absolute top-2 right-4 px-2 py-1 z-10">
        <button
          onClick={handleCopy}
          className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:shadow-md active:bg-pink-200"
        >
          {copied ? (
            <div className="flex items-center flex-row">
              <svg
                className="w-3 h-3 text-white me-1.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
              <span>Copied!</span>
            </div>
          ) : (
            <div>
              <span>Copy</span>
            </div>
          )}
        </button>
      </div>

      {/* Light Mode - Material Light Theme */}
      <div className="light:block">
        <SyntaxHighlighter
          language="python"
          style={materialLight}
          customStyle={{
            background: "none",
            fontSize: "0.875rem",
            color: "#2d2d2d",
          }}
          codeTagProps={{
            style: {
              background: "none",
            },
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default SubmissionCode;
