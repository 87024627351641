import React, { useState, useEffect, useRef } from "react";

export const OthelloBattleView = ({
    player1,
    player2,
    user,
    setShowWinner,
    setWinner,
    setInvalid,
    setTimeoutFlag,
    setError,
}) => {
    const emptyBoard = () => {
        const board = Array(8)
            .fill(0)
            .map(() => Array(8).fill(0));
        board[3][3] = 2;
        board[3][4] = 1;
        board[4][3] = 1;
        board[4][4] = 2;
        return board;
    };

    const [moves, setMoves] = useState([emptyBoard()]);
    const [gameEnd, setGameEnd] = useState(false);
    const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const [winnerFound, setWinnerFound] = useState(false);

    const socketRef = useRef(null);
    const intervalRef = useRef(null);
    const movesRef = useRef(moves);
    const winnerFoundRef = useRef(winnerFound);

    useEffect(() => { movesRef.current = moves; }, [moves]);
    useEffect(() => { winnerFoundRef.current = winnerFound; }, [winnerFound]);

    const board = moves[currentMoveIndex];

    const initializeWebSocket = async () => {
        const token = await user.getIdToken();
        socketRef.current = new WebSocket(`${process.env.REACT_APP_endpointURL}/game/match`);

        socketRef.current.onopen = () => {
            socketRef.current.send(
                JSON.stringify({
                    user: token,
                    "bot1-ID": player1.botId,
                    "bot2-ID": player2.botId,
                    game: "othello",
                })
            );
            console.log("Connection established");
        };

        socketRef.current.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log(data);
                if (data.winner) {
                    setWinner(data.winner);
                    setWinnerFound(true);
                }
                if (data.move && !data.invalid) {
                    setMoves((prev) => [...prev, JSON.parse(data.state)]);
                }
                if (data.exception) setError(true);
                if (data.invalid) setInvalid(true);
                if (data.timeout) setTimeoutFlag(true);
            } catch (err) {
                console.error("Error:", err);
            }
        };

        socketRef.current.onerror = (error) => {
            console.error("Error:", error);
        };

        socketRef.current.onclose = () => {
            console.log("Connection closed");
        };
    };

    const stepForward = () => {
        if (currentMoveIndex < movesRef.current.length - 1) {
            const nextMoveIndex = currentMoveIndex + 1;
            if (winnerFoundRef.current && nextMoveIndex === movesRef.current.length - 1) {
                setGameEnd(true);
            }
            setCurrentMoveIndex(nextMoveIndex);
        }
    };

    const stepBackward = () => {
        if (currentMoveIndex > 0) {
            setCurrentMoveIndex((prev) => prev - 1);
            setGameEnd(false);
        }
    };

    const handleRestart = () => {
        setCurrentMoveIndex(0);
        setIsPlaying(true);
        setGameEnd(false);
    };

    useEffect(() => {
        if (isPlaying && !gameEnd) {
            intervalRef.current = setInterval(() => {
                setCurrentMoveIndex((prev) => {
                    if (prev < movesRef.current.length - 1) {
                        if (winnerFoundRef.current && prev + 1 >= movesRef.current.length - 1) {
                            setGameEnd(true);
                        }
                        return prev + 1;
                    }
                    else {
                        if (winnerFoundRef.current && prev >= movesRef.current.length - 1) {
                            setGameEnd(true);
                        }
                        return prev;
                    }
                });
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }
    }, [isPlaying]);

    useEffect(() => {
        if (gameEnd) {
            setIsPlaying(false);
            setShowWinner(true);
            clearInterval(intervalRef.current);
        } else {
            setShowWinner(false);
        }
    }, [gameEnd]);

    useEffect(() => {
        initializeWebSocket();
        return () => {
            if (socketRef.current) {
                console.log("Closing connection");
                socketRef.current.close();
            }
        };
    }, [user, player1, player2]);

    return (
        <div className="flex flex-col">
            <div className="flex justify-center items-center">
                <div className="grid w-fit border-4 border-black">
                    {board.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex bg-green-500 w-fit">
                            {row.map((cell, colIndex) => (
                                <div
                                    key={colIndex}
                                    className="relative flex items-center justify-center p-1 border border-black"
                                >
                                    <div
                                        className={`w-7 h-7 rounded-full transform transition-all duration-500 ease-in-out ${cell === 1
                                            ? "bg-black" // Player 1 (Black)
                                            : cell === 2
                                                ? "bg-white" // Player 2 (White)
                                                : "" // Empty space
                                            }`}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-row justify-center gap-2 p-2 pt-4">
                <button
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                    onClick={stepBackward}
                    disabled={isPlaying ? true : currentMoveIndex === 0}
                >
                    Previous
                </button>
                <button
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                    onClick={stepForward}
                    disabled={isPlaying || gameEnd}
                >
                    Next
                </button>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                    onClick={() => setIsPlaying(!isPlaying)}
                    disabled={gameEnd}
                >
                    {isPlaying ? "Pause" : "Start"}
                </button>
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded"
                    onClick={handleRestart}
                >
                    Restart
                </button>
            </div>
        </div>
    );
};
