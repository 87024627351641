import React, { useState } from "react";
import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "./FirebaseAuth";
import GoogleLogo from "../Images/Logos/google.webp";

const Login = ({ setMode }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorTxt, setErrorTxt] = useState("");

  const handleEmailAuth = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setErrorTxt("");
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorTxt("Invalid credentials.");
      });
  };

  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        setErrorTxt("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginBtnClick = () => {
    handleEmailAuth(email, password);
  };

  const navigateToRegister = () => {
    setErrorTxt("");
    setMode("register");
  };

  const navigateToForgotPassword = () => {
    setErrorTxt("");
    setMode("password");
  };

  const loginEnabled = email !== "" && password !== "";

  return (
    <div className="login-page flex justify-center items-center w-full h-[calc(100vh-120px)]">
      <div className="login-container w-[440px] pb-10 bg-surfaceContain-light dark:bg-surfaceContain-dark rounded-lg shadow-md border p-6">
        <h2 className="text-4xl font-bold text-center select-none login-title text-onSurface-light dark:text-onSurface-dark">
          Login
        </h2>
        <div className="flex justify-center email-input mt-7">
          <input
            type="email"
            required
            className="w-full p-2 border rounded-md shadow-sm border-border-light dark:border-border-dark bg-surface-light dark:bg-surface-dark text-onSurface-light dark:text-onSurface-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark focus:border-primary-light dark:focus:border-primary-dark"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="flex justify-center mt-4 password-input">
          <input
            type="password"
            required
            className="w-full p-2 border rounded-md shadow-sm border-border-light dark:border-border-dark bg-surface-light dark:bg-surface-dark text-onSurface-light dark:text-onSurface-dark focus:outline-none focus:ring-2 focus:ring-primary-light dark:focus:ring-primary-dark focus:border-primary-light dark:focus:border-primary-dark"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        {errorTxt && (
          <div className="mt-2 text-sm text-red-500 error-txt">{errorTxt}</div>
        )}
        <div className="flex justify-center mt-7">
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            disabled={!loginEnabled}
            onClick={handleLoginBtnClick}
          >
            Login
          </button>
        </div>
        <div className="flex justify-between under-txt-container mt-7">
          <span
            className="mt-2 text-sm transition duration-100 cursor-pointer select-none under-txt text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:brightness-75"
            onClick={navigateToRegister}
          >
            Create Account
          </span>
          <span
            className="mt-2 text-sm transition duration-100 cursor-pointer select-none under-txt text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark hover:brightness-75"
            onClick={navigateToForgotPassword}
          >
            Forgot Password
          </span>
        </div>
        <div className="flex items-center justify-center my-5 or-container">
          <div className="flex-grow border-t or-divider border-border-light dark:border-border-dark"></div>
          <span className="px-2 text-sm select-none or-text text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
            Or
          </span>
          <div className="flex-grow border-t or-divider border-border-light dark:border-border-dark"></div>
        </div>
        <div
          className="flex items-center p-2 transition duration-100 border rounded-md cursor-pointer auth-container border-border-light dark:border-border-dark hover:brightness-90"
          onClick={handleGoogleAuth}
        >
          <img
            className="h-8 mr-2 auth-logo"
            src={GoogleLogo}
            alt="Google logo"
          />
          <span className="font-semibold auth-txt text-onSurface-light dark:text-onSurface-dark">
            Sign in with Google
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
