import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Images/Logos/brainrot.png";
import Brain from "../Images/Logos/brain.png";
import { useNavigate } from "react-router-dom";
import { HiMenu, HiX } from "react-icons/hi";
import { emojiMap } from "../Constants/Icons";

function Header({ user, icon }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu toggle

  // Function to check if the current path matches the link path
  const isActive = (path) => location.pathname === path;

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="flex justify-center">
      <div className="fixed z-20 px-4 py-2 w-full border-b bg-gray-50">
        <div className="font-semibold text-md flex items-center justify-between lg:justify-center lg:gap-96 w-full gap-8">
          {/* Logo */}
          <Link to="/">
            {/* Show Logo only on small and large screens */}
            <img
              src={Logo}
              alt="Logo"
              className="h-12 block sm:hidden lg:block"
            />
            {/* Show Brain only on medium screens */}
            <img
              src={Brain}
              alt="Brain"
              className="h-12 hidden sm:block lg:hidden"
            />
          </Link>

          {/* Mobile Menu Toggle */}
          <div className="flex items-center sm:hidden">
            <button onClick={toggleMenu} aria-label="Toggle Menu">
              {menuOpen ? (
                <HiX className="text-2xl text-onSurface-light" />
              ) : (
                <HiMenu className="text-2xl text-onSurface-light" />
              )}
            </button>
          </div>

          {/* Menu - Hidden on Mobile */}
          <div className="items-center hidden gap-8 sm:flex">
            <Link
              to="/games"
              className={
                isActive("/games")
                  ? "text-onSurface-light hover:text-primary-light"
                  : "text-onSurfaceSecond-light hover:text-primary-light"
              }
            >
              games
            </Link>
            <Link
              to="/leaderboard"
              className={
                isActive("/leaderboard")
                  ? "text-onSurface-light hover:text-primary-light"
                  : "text-onSurfaceSecond-light hover:text-primary-light"
              }
            >
              leaderboard
            </Link>

            {!user ? (
              <button
                onClick={() => navigate("/profile")}
                className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:shadow-md hover:bg-pink-100 active:bg-pink-200"
              >
                login
              </button>
            ) : (
              <Link to="/profile">
                <div className="flex items-center justify-center bg-primary-light rounded-full size-8">
                  <p className="text-2xl">{emojiMap[icon]}</p>
                </div>
              </Link>
            )}
          </div>
        </div>

        {/* Mobile Menu - Visible on Mobile */}
        {menuOpen && (
          <div className="flex flex-col mt-4 space-y-4 sm:hidden">
            <Link
              to="/games"
              className={
                isActive("/games")
                  ? "text-onSurface-light underline underline-offset-4"
                  : "text-onSurfaceSecond-light"
              }
              onClick={toggleMenu}
            >
              games
            </Link>
            <Link
              to="/leaderboard"
              className={
                isActive("/leaderboard")
                  ? "text-onSurface-light underline underline-offset-4"
                  : "text-onSurfaceSecond-light"
              }
              onClick={toggleMenu}
            >
              leaderboard
            </Link>

            {!user ? (
              <button
                onClick={() => {
                  navigate("/profile");
                  toggleMenu();
                }}
                className="flex items-center px-4 py-2 text-sm rounded-lg w-fit bg-primary"
              >
                login
              </button>
            ) : (
              <Link to="/profile" onClick={toggleMenu}>
                <div className="flex items-center justify-center pt-[6px] bg-primary-light rounded-full size-8">
                  <p className="text-2xl">{emojiMap[icon]}</p>
                </div>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
