import React from "react";
import BrianRot from "../Images/Logos/brain.png";
import BrainRotText from "../Images/Logos/brainrot_text.png";
import { MdEmail } from "react-icons/md";
import { FaLinkedin, FaDiscord } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TermsOfServicePopup from "../Components/Terms";
import WorkingHardBrain from "../Images/Brains/working.png";
import IdeaBrain from "../Images/Brains/idea.png";

function About() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col justify-center items-center pt-16">
      <div className="container mx-auto text-center flex flex-col items-center justify-center gap-8 flex-grow">
        <div className="flex flex-col items-center justify-center">
          <img
            src={BrianRot}
            alt="brAIn rot logo"
            className="w-24 h-24 lg:w-36 lg:h-36 mb-4"
          />
          <img
            src={BrainRotText}
            alt="brAIn rot text logo"
            className="w-auto h-12 lg:h-24"
          />
        </div>
        <div className="flex flex-col md:flex-row gap-6 text-2xl items-center justify-center w-full max-w-2xl p-2">
          <button
            onClick={() => navigate("/games")}
            className="hover:bg-pink-100 bg-white w-full flex flex-row px-8 py-6 font-semibold justify-center items-center text-center rounded-2xl shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 border-2 border-primary gap-6"
          >
            <img
              src={WorkingHardBrain}
              alt="Working Hard Brain"
              className="h-10 mb-2"
            />
            <div className="flex flex-col">
              <div className="text-3xl">compete</div>
              <div className="text-sm italic mt-1">for developers</div>
            </div>
          </button>

          <a
            href="https://calendly.com/brainrot4ai/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:bg-pink-100 bg-white w-full flex flex-row px-8 py-6 font-semibold justify-center items-center text-center rounded-2xl shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 border-2 border-primary gap-6"
          >
            <div className="flex flex-col">
              <div className="text-3xl">host</div>
              <div className="text-sm italic mt-1">for businesses</div>
            </div>
            <img src={IdeaBrain} alt="Idea Brain" className="h-12 mb-2" />
          </a>
        </div>
      </div>

      <footer>
        <div className="container mx-auto text-center">
          <div className="flex justify-center mb-4">
            <a
              href="https://www.linkedin.com/company/brain-rot-code"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 hover:underline mx-2"
            >
              <FaLinkedin className="text-xl text-primary" />
              <p className="hidden sm:flex">LinkedIn</p>
            </a>

            <a
              href="mailto:brainrot4ai@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 hover:underline mx-2"
            >
              <MdEmail className="text-xl text-primary" />
              <p className="hidden sm:flex">Email</p>
            </a>

            <a
              href="https://discord.gg/atjYvGbdrK"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 hover:underline mx-2"
            >
              <FaDiscord className="text-xl text-primary" />
              <p className="hidden sm:flex">Discord</p>
            </a>

            <div className="flex items-center gap-2 hover:underline mx-2">
              <TermsOfServicePopup />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default About;
