import React, { useState, useEffect } from "react";
import { getBotMove } from "../API/Bots";

export const TicTacToePlayView = ({
  contestId,
  botId,
  user,
  setException,
  setStdout,
  setInvalid,
  setTimeoutFlag,
  setMoveTime,
  setMove,
}) => {
  const [playerSymbol, setPlayerSymbol] = useState("X");

  return (
    <div>
      <TicTacToePlayBot
        key={playerSymbol} // Changing this key will force a rerender
        contestId={contestId}
        botId={botId}
        user={user}
        playerSymbol={playerSymbol}
        setException={setException}
        setStdout={setStdout}
        setTimeout={setTimeoutFlag}
        setInvalid={setInvalid}
        setMoveTime={setMoveTime}
        setMove={setMove}
      />
      <div className="flex justify-center mt-4">
        <button
          className="bg-primary text-white rounded-lg p-2 px-4 shadow-md"
          onClick={() => setPlayerSymbol(playerSymbol === "X" ? "O" : "X")}
        >
          Play as {playerSymbol === "X" ? "O" : "X"}
        </button>
      </div>
    </div>
  );
};

const TicTacToePlayBot = ({
  botId,
  user,
  setException,
  setStdout,
  playerSymbol,
  setInvalid,
  setTimeout,
  setMoveTime,
  setMove,
}) => {
  const emptyBoard = [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ];
  const [board, setBoard] = useState(emptyBoard);
  const [winner, setWinner] = useState("");
  const [loading, setLoading] = useState(false);

  const getMove = (turn, board) => {
    setInvalid(false);
    getBotMove(
      user,
      "tictactoe",
      botId,
      JSON.stringify(board),
      JSON.stringify(turn)
    )
      .then((result) => {
        if (result.move && !result.invalid) {
          setMoveTime(result.time);
          setMove(result.move);
          setBoard((prevBoard) => {
            const newBoard = prevBoard.map((row) => [...row]);
            const move = result.move.slice(1, -1);
            const row = parseInt(move.split(",")[0]);
            const col = parseInt(move.split(",")[1].slice(1));
            newBoard[row][col] = turn;
            return newBoard;
          });
        }

        if (result.winner) {
          setWinner(
            result.winner === 1
              ? playerSymbol === "X"
                ? "You won :)"
                : "You lost :("
              : result.winner === 2
              ? playerSymbol === "O"
                ? "You won :)"
                : "You lost :("
              : "It's a draw!"
          );
        }

        setStdout(result.stdout);
        setException(result.exception);
        if (result.invalid) setInvalid(true);
        if (result.timeout) setTimeout(true);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const onTileClick = (row, col) => {
    if (!board[row][col] && !winner && !loading) {
      setBoard((prevBoard) => {
        const newBoard = prevBoard.map((r) => [...r]);
        newBoard[row][col] = playerSymbol == "X" ? 1 : 2;
        setLoading(true);
        getMove(playerSymbol == "X" ? 2 : 1, newBoard);
        return newBoard;
      });
    }
  };

  useEffect(() => {
    if (playerSymbol === "O") {
      getMove(1, emptyBoard);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {winner ? (
        <p className="text-onSurface p-2">{winner}</p>
      ) : (
        <p className="text-onSurface p-2">play me!</p>
      )}
      <div className="grid grid-cols-3 w-60 h-60">
        {board.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            const isBottom = rowIndex < 2; // Add bottom border if not last row
            const isRight = colIndex < 2; // Add right border if not last column

            return (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={`w-20 h-20 flex items-center justify-center ${
                  isBottom ? "border-b-4" : ""
                } ${isRight ? "border-r-4" : ""} border-gray-500`}
                onClick={() => onTileClick(rowIndex, colIndex)}
              >
                <div
                  className={`text-4xl font-chalk ${
                    cell == 1
                      ? "text-blue-500"
                      : cell == 2
                      ? "text-red-500"
                      : ""
                  }`}
                >
                  {cell == 1 ? "X" : cell == 2 ? "O" : ""}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
