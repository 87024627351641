import React, { useState, useEffect } from "react";
import { getBotMove } from "../API/Bots";

export const OthelloPlayView = ({
  contestId,
  botId,
  user,
  setException,
  setStdout,
  setInvalid,
  setTimeoutFlag,
  setMoveTime,
  setMove,
}) => {
  const [playerColor, setPlayerColor] = useState("black");

  return (
    <div>
      <OthelloPlayBot
        key={playerColor} // Changing this key will force a rerender
        contestId={contestId}
        botId={botId}
        user={user}
        playerColor={playerColor}
        setException={setException}
        setStdout={setStdout}
        setTimeout={setTimeoutFlag}
        setInvalid={setInvalid}
        setMoveTime={setMoveTime}
        setMove={setMove}
      />
      <div className="flex justify-center">
        <button
          className="bg-primary text-white rounded-lg p-2 px-4 shadow-md"
          onClick={() =>
            setPlayerColor(playerColor === "white" ? "black" : "white")
          }
        >
          play as {playerColor === "white" ? "black" : "white"}
        </button>
      </div>
    </div>
  );
};

const OthelloPlayBot = ({
  botId,
  user,
  setException,
  setStdout,
  playerColor,
  setInvalid,
  setTimeout,
  setMoveTime,
  setMove,
}) => {
  const initialBoardState = () => {
    const board = Array(8)
      .fill(0)
      .map(() => Array(8).fill(0));
    board[3][3] = 2;
    board[3][4] = 1;
    board[4][3] = 1;
    board[4][4] = 2;
    return board;
  };

  const [board, setBoard] = useState(initialBoardState());
  const [winner, setWinner] = useState("");
  const [playableTiles, setPlayableTiles] = useState([]);

  const player = playerColor === "black" ? 1 : 2;
  const botPlayer = 3 - player;

  const boardSize = 8;
  const directions = [
    [-1, 0],
    [1, 0],
    [0, -1],
    [0, 1],
    [-1, -1],
    [-1, 1],
    [1, -1],
    [1, 1],
  ];

  const getMove = (turn, board) => {
    getBotMove(user, "othello", botId, JSON.stringify(board), turn.toString())
      .then((result) => {
        if (result.move && !result.invalid) {
          setMoveTime(result.time);
          setMove(result.move);
          const move = result.move.slice(1, -1);
          const x = parseInt(move.split(",")[0]);
          const y = parseInt(move.split(",")[1].slice(1));
          const newBoard = applyMove(board, [x, y], turn);
          setBoard(newBoard);
          const tiles = getPlayableTiles(newBoard, player);
          setPlayableTiles(tiles);
        }
        if (result.winner) {
          setPlayableTiles([]);
          if (result.winner === 1) {
            setWinner(playerColor === "black" ? "You won!" : "You lost :(");
          } else if (result.winner === 2) {
            setWinner(playerColor === "white" ? "You won!" : "You lost :(");
          } else {
            setWinner("It's a draw!");
          }
        }

        setStdout(result.stdout);
        setException(result.exception);

        if (result.exception) {
          setError(true);
          setPlayableTiles([]);
        } else if (result.invalid) {
          setInvalid(true);
          setPlayableTiles([]);
        } else if (result.timeout) {
          setTimeout(true);
          setPlayableTiles([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isValidMove = (board, move, player) => {
    const [x, y] = move;
    if (board[x][y] !== 0) return false;

    const opponent = 3 - player;
    let flips = [];

    directions.forEach(([dx, dy]) => {
      let currentX = x + dx;
      let currentY = y + dy;
      let tempFlips = [];

      while (
        currentX >= 0 &&
        currentX < boardSize &&
        currentY >= 0 &&
        currentY < boardSize
      ) {
        if (board[currentX][currentY] === opponent) {
          tempFlips.push([currentX, currentY]);
        } else if (board[currentX][currentY] === player) {
          if (tempFlips.length > 0) {
            flips.push(...tempFlips);
          }
          break;
        } else {
          break;
        }
        currentX += dx;
        currentY += dy;
      }
    });

    return flips.length > 0 ? flips : false;
  };

  const applyMove = (board, move, player) => {
    const [x, y] = move;
    const newBoard = board.map((row) => [...row]);

    newBoard[x][y] = player;

    const flips = isValidMove(board, move, player);
    if (flips) {
      flips.forEach(([fx, fy]) => {
        newBoard[fx][fy] = player;
      });
    }

    return newBoard;
  };

  const getPlayableTiles = (board, player) => {
    let tiles = [];
    board.forEach((row, x) => {
      row.forEach((_, y) => {
        if (isValidMove(board, [x, y], player)) {
          tiles.push([x, y]);
        }
      });
    });
    return tiles;
  };

  useEffect(() => {
    setException("");
    if (playerColor == "white") {
      getMove(botPlayer, initialBoardState());
    } else {
      const tiles = getPlayableTiles(board, player);
      setPlayableTiles(tiles);
    }
  }, []);

  const handleTileClick = (x, y) => {
    const move = [x, y];
    if (playableTiles.some(([px, py]) => px === x && py === y)) {
      const newBoard = applyMove(board, move, player);
      setBoard(newBoard);
      if (winner === "") {
        window.setTimeout(() => {
          getMove(botPlayer, newBoard);
        }, 1000);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 relative">
      {winner ? (
        <p className="text-onSurface p-2">{winner}</p>
      ) : (
        <p className="text-onSurface p-2">play me!</p>
      )}
      <div className="flex flex-col justify-center">
        <div className="flex justify-center items-center">
          <div className="grid border-4 border-black">
            {board.map((row, rowIndex) => (
              <div key={rowIndex} className="flex bg-green-500">
                {row.map((cell, colIndex) => {
                  const isPlayable = playableTiles.some(
                    ([px, py]) => px === rowIndex && py === colIndex
                  );
                  return (
                    <div
                      key={colIndex}
                      className={`relative flex items-center justify-center border border-black ${
                        isPlayable
                          ? "bg-green-300 hover:bg-green-200 transition ease-in-out cursor-pointer"
                          : ""
                      }`}
                      onClick={() => handleTileClick(rowIndex, colIndex)}
                    >
                      <div className="p-1">
                        <div
                          className={`w-7 h-7 rounded-full transform transition-all duration-500 ease-in-out ${
                            cell === 1
                              ? "bg-black"
                              : cell === 2
                              ? "bg-white"
                              : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
