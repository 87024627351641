import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import { GamesInfo } from "../Constants/Games";

const UserBots = ({ bots }) => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  const filteredBots = bots
    ? bots.filter((bot) =>
        ["botname", "game"].some((key) =>
          bot[key]
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(searchQuery.toLowerCase().replace(/\s+/g, ""))
        )
      )
    : [];

  return (
    <div className="w-full px-4 p-8">
      <SearchBar setSearchTerm={setSearchQuery} />
      {/* Bots Grid */}
      <div className="flex flex-wrap justify-center items-center sm:justify-start px-10 gap-4 p-4">
        {filteredBots.length > 0 ? (
          filteredBots.map((bot) => (
            <div
              key={bot.botId}
              className="w-48 h-48 rounded-lg border-[1px] bg-white shadow-md hover:shadow-lg transition p-4 cursor-pointer flex flex-col justify-between gap-4 items-center"
              onClick={() => navigate(`/bots/${bot.game}/${bot.botId}`)}
            >
              <img
                src={GamesInfo[bot.game].image}
                alt={bot.game}
                className="rounded-lg w-24 h-24 flex items-center"
              />

              {/* Bot Details */}
              <div className="flex flex-col items-center">
                <h3 className="text-xl font-bold text-gray-800 text-center break-all">
                  {bot.botname}
                </h3>
                <p className="text-md text-gray-400">{bot.rating}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg text-onSurfaceSecond p-2">No bots found</p>
        )}
      </div>
    </div>
  );
};

export default UserBots;
