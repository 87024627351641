import React, { useState, useEffect, useRef } from "react";

export const CheckersBattleView = ({
    player1,
    player2,
    user,
    setShowWinner,
    setWinner,
    setInvalid,
    setTimeoutFlag,
    setError,
}) => {
    // 1 = Red, 2 = Red King, 3 = Black, 4 = Black King
    const initialBoardState = [
        [0, 1, 0, 1, 0, 1, 0, 1],
        [1, 0, 1, 0, 1, 0, 1, 0],
        [0, 1, 0, 1, 0, 1, 0, 1],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [3, 0, 3, 0, 3, 0, 3, 0],
        [0, 3, 0, 3, 0, 3, 0, 3],
        [3, 0, 3, 0, 3, 0, 3, 0],
    ];

    const [moves, setMoves] = useState([initialBoardState]);
    const [gameEnd, setGameEnd] = useState(false);
    const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const [winnerFound, setWinnerFound] = useState(false);

    const socketRef = useRef(null);
    const intervalRef = useRef(null);
    const movesRef = useRef(moves);
    const winnerFoundRef = useRef(winnerFound);

    useEffect(() => { movesRef.current = moves; }, [moves]);
    useEffect(() => { winnerFoundRef.current = winnerFound; }, [winnerFound]);

    const board = moves[currentMoveIndex];

    const FENToBoard = (fenString) => {
        const board = Array(8).fill().map(() => Array(8).fill(0));
        
        const fenMatch = fenString.match(/\[FEN "([^"]+)"\]/);
        if (!fenMatch) return board;
        
        const parts = fenMatch[1].split(':');
        if (parts.length < 4) return board;
        
        const playerTurn = parts[0];
        const whitePieces = parts[2].substring(1).split(',');
        const blackPieces = parts[3].substring(1).split(',');
        
        const placePiece = (position, pieceType) => {
            position = parseInt(position, 10);
            const row = Math.floor((position - 1) / 4);
            const col = ((position - 1) % 4) * 2 + (row % 2 === 0 ? 1 : 0);
            
            if (row >= 0 && row < 8 && col >= 0 && col < 8) {
                board[row][col] = pieceType;
            }
        };
        
        for (let piece of whitePieces) {
            if (piece.startsWith('K')) {
                const position = piece.substring(1);
                placePiece(position, playerTurn === "B" ? 2 : 4);
            } else {
                placePiece(piece, playerTurn === "B" ? 1 : 3);
            }
        }
        
        for (let piece of blackPieces) {
            if (piece.startsWith('K')) {
                const position = piece.substring(1);
                placePiece(position, playerTurn === "B" ? 4 : 2);
            } else {
                placePiece(piece, playerTurn === "B" ? 3 : 1);
            }
        }
        
        return board;
    };

    const initializeWebSocket = async () => {
        const token = await user.getIdToken();
        socketRef.current = new WebSocket(`${process.env.REACT_APP_endpointURL}/game/match`);

        socketRef.current.onopen = () => {
            socketRef.current.send(
                JSON.stringify({
                    user: token,
                    "bot1-ID": player1.botId,
                    "bot2-ID": player2.botId,
                    game: "checkers",
                })
            );
            console.log("Connection established");
        };

        socketRef.current.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log(data);
                if (data.winner) {
                    setWinner(data.winner);
                    setWinnerFound(true);
                }
                if (data.state && !data.invalid) {
                    const newBoard = FENToBoard(data.state);
                    setMoves((prev) => [...prev, newBoard]);
                }
                if (data.exception) setError(true);
                if (data.invalid) setInvalid(true);
                if (data.timeout) setTimeoutFlag(true);
            } catch (err) {
                console.error("Error:", err);
            }
        };

        socketRef.current.onerror = (error) => {
            console.error("Error:", error);
        };

        socketRef.current.onclose = () => {
            console.log("Connection closed");
        };
    };

    const stepForward = () => {
        if (currentMoveIndex < movesRef.current.length - 1) {
            const nextMoveIndex = currentMoveIndex + 1;
            if (winnerFoundRef.current && nextMoveIndex === movesRef.current.length - 1) {
                setGameEnd(true);
            }
            setCurrentMoveIndex(nextMoveIndex);
        }
    };

    const stepBackward = () => {
        if (currentMoveIndex > 0) {
            setCurrentMoveIndex((prev) => prev - 1);
            setGameEnd(false);
        }
    };

    const handleRestart = () => {
        setCurrentMoveIndex(0);
        setIsPlaying(true);
        setGameEnd(false);
    };

    useEffect(() => {
        if (isPlaying && !gameEnd) {
            intervalRef.current = setInterval(() => {
                setCurrentMoveIndex((prev) => {
                    if (prev < movesRef.current.length - 1) {
                        if (winnerFoundRef.current && prev + 1 >= movesRef.current.length - 1) {
                            setGameEnd(true);
                        }
                        return prev + 1;
                    }
                    else {
                        if (winnerFoundRef.current && prev >= movesRef.current.length - 1) {
                            setGameEnd(true);
                        }
                        return prev;
                    }
                });
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }
    }, [isPlaying]);

    useEffect(() => {
        if (gameEnd) {
            setIsPlaying(false);
            setShowWinner(true);
            clearInterval(intervalRef.current);
        } else {
            setShowWinner(false);
        }
    }, [gameEnd]);

    useEffect(() => {
        initializeWebSocket();
        return () => {
            if (socketRef.current) {
                console.log("Closing connection");
                socketRef.current.close();
            }
        };
    }, [user, player1, player2]);

    return (
        <div className="flex flex-col">
            <div className="flex justify-center">
                <div className="grid bg-amber-900 rounded-lg p-1.5">
                    {board.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex">
                            {row.map((cell, colIndex) => {
                                const isDark = (rowIndex + colIndex) % 2 === 1;
                                return (
                                    <div
                                        key={colIndex}
                                        style={{ backgroundColor: isDark ? "#9F7657" : "#D6AE87" }}
                                        className={`w-9 h-9 flex items-center justify-center`}
                                    >
                                        {cell !== 0 && (
                                            <div
                                                style={{ backgroundColor: (cell === 1 || cell === 2) ? "#CA0918" : "black" }}
                                                className={`w-8 h-8 rounded-full flex items-center justify-center`}
                                            >
                                                {(cell === 2 || cell === 4) && (
                                                    <svg
                                                        className="w-4 h-4 fill-current text-yellow-400"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                    </svg>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-row justify-center gap-2 p-2 pt-4">
                <button
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                    onClick={stepBackward}
                    disabled={isPlaying ? true : currentMoveIndex === 0}
                >
                    Previous
                </button>
                <button
                    className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
                    onClick={stepForward}
                    disabled={isPlaying || gameEnd}
                >
                    Next
                </button>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                    onClick={() => setIsPlaying(!isPlaying)}
                    disabled={gameEnd}
                >
                    {isPlaying ? "Pause" : "Start"}
                </button>
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded"
                    onClick={handleRestart}
                >
                    Restart
                </button>
            </div>
        </div>
    );
};
