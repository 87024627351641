import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./FirebaseAuth";
import Auth from "./Auth";
import Loading from "../Components/Loading";

const ProtectedRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
  }, [user, loading]);

  if (loading) {
    return (
      <div className="grid justify-center p-10">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="grid justify-center p-4">
        <div className="text-red-500 mt-2">An error occured!</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="pt-16">
        <Auth />
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;
