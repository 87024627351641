import React, { useState, useEffect } from "react";
import { getBotMove } from "../API/Bots";

export const CheckersPlayView = ({
    contestId,
    botId,
    user,
    setException,
    setStdout,
    setInvalid,
    setTimeoutFlag,
    setMoveTime,
    setMove,
}) => {
    const [playerColor, setPlayerColor] = useState("black");

    return (
        <div>
            <CheckersPlayBot
                key={playerColor}
                contestId={contestId}
                botId={botId}
                user={user}
                playerColor={playerColor}
                setException={setException}
                setStdout={setStdout}
                setTimeout={setTimeoutFlag}
                setInvalid={setInvalid}
                setMoveTime={setMoveTime}
                setMove={setMove}
            />
            <div className="flex justify-center">
                <button
                    className="bg-primary text-white rounded-lg p-2 px-4 shadow-md"
                    onClick={() =>
                        setPlayerColor(playerColor === "red" ? "black" : "red")
                    }
                >
                    play as {playerColor === "red" ? "black" : "red"}
                </button>
            </div>
        </div>
    );
};

const CheckersPlayBot = ({
    botId,
    user,
    setException,
    setStdout,
    playerColor,
    setInvalid,
    setTimeout,
    setMoveTime,
    setMove,
}) => {
    const botColor = playerColor === "red" ? "1" : "2";

    // 1 = Red, 2 = Red King, 3 = Black, 4 = Black King
    const emptyBoard = [
        [0, 1, 0, 1, 0, 1, 0, 1],
        [1, 0, 1, 0, 1, 0, 1, 0],
        [0, 1, 0, 1, 0, 1, 0, 1],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0],
        [3, 0, 3, 0, 3, 0, 3, 0],
        [0, 3, 0, 3, 0, 3, 0, 3],
        [3, 0, 3, 0, 3, 0, 3, 0],
    ];

    const [board, setBoard] = useState(emptyBoard);
    const [winner, setWinner] = useState("");
    const [playableTiles, setPlayableTiles] = useState([]);
    const [selectedTile, setSelectedTile] = useState(null);
    const [possibleMoves, setPossibleMoves] = useState([]);

    const boardToFEN = (board) => {
        let redPieces = [];
        let blackPieces = [];
        let redKings = [];
        let blackKings = [];

        for (let row = 0; row < 8; row++) {
            for (let col = 0; col < 8; col++) {
                if ((row + col) % 2 === 1) {
                    const position = row * 4 + Math.floor(col / 2) + 1;
                    if (board[row][col] === 1) {
                        redPieces.push(position);
                    } else if (board[row][col] === 2) {
                        redKings.push(position);
                    } else if (board[row][col] === 3) {
                        blackPieces.push(position);
                    } else if (board[row][col] === 4) {
                        blackKings.push(position);
                    }
                }
            }
        }

        redPieces.sort((a, b) => a - b);
        blackPieces.sort((a, b) => a - b);
        redKings.sort((a, b) => a - b);
        blackKings.sort((a, b) => a - b);

        const whitePiecesFEN = [...redPieces, ...redKings.map(k => `K${k}`)].join(',');
        const blackPiecesFEN = [...blackPieces, ...blackKings.map(k => `K${k}`)].join(',');
        const playerOne = playerColor === "red" ? "W" : "B"
        return `[FEN "${playerOne}:W${blackPiecesFEN}:B${whitePiecesFEN}"]`;
    };

    const applyMoveToBoard = (board, move) => {
        const delimiter = move.includes("x") ? "x" : "-";
        const positions = move.split(delimiter).map(Number);
        if (positions.length < 2) return board;
    
        const indexToCoord = (index) => {
            const row = Math.floor((index - 1) / 4);
            const col = ((index - 1) % 4) * 2 + (row % 2 === 0 ? 1 : 0);
            return { row, col };
        };
    
        const start = indexToCoord(positions[0]);
        const end = indexToCoord(positions[positions.length - 1]);
    
        const piece = board[start.row][start.col];
        if (piece === 0) return board;
    
        const newBoard = board.map(row => [...row]);
        newBoard[end.row][end.col] = piece;
        newBoard[start.row][start.col] = 0;
    
        if (delimiter === "x") {
            for (let i = 0; i < positions.length - 1; i++) {
                const from = indexToCoord(positions[i]);
                const to = indexToCoord(positions[i + 1]);
                const midRow = (from.row + to.row) / 2;
                const midCol = (from.col + to.col) / 2;
                if (Math.abs(from.row - to.row) === 2) {
                    newBoard[midRow][midCol] = 0;
                }
            }
        }
    
        if ((piece === 1 && end.row === 7) || (piece === 3 && end.row === 0)) {
            newBoard[end.row][end.col] = piece + 1; // Promote to king
        }
    
        return newBoard;
    };

    const getMove = (turn, board) => {
        const FEN = boardToFEN(board);
        if (/WB|B"/.test(FEN)) { // Game ended
            setWinner("You won :)");
            return;
        }
        getBotMove(user, "checkers", botId, FEN, turn)
            .then((result) => {
                console.log(result);
                if (result.move && !result.invalid) {
                    setMoveTime(result.time);
                    setMove(result.move);

                    const newBoard = applyMoveToBoard(board, result.move);
                    setBoard(newBoard);

                    if (result.winner) {
                        setPlayableTiles([]);
                    } else {
                        const tiles = getPlayableTiles(newBoard);
                        setPlayableTiles(tiles);
                    }
                }
                if (result.winner) {
                    if (result.winner === 1) {
                        setWinner(playerColor === "black" ? "You won :)" : "You lost :(");
                    } else if (result.winner === 2) {
                        setWinner(playerColor === "red" ? "You won :)" : "You lost :(");
                    } else {
                        setWinner("It's a draw!");
                    }
                }

                setStdout(result.stdout);
                setException(result.exception);

                if (result.invalid) {
                    setInvalid(true);
                } else if (result.timeout) {
                    setTimeout(true);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const hasJump = (board, playerPieces) => {
        let hasJump = false;

        for (let row = 0; row < 8; row++) {
            for (let col = 0; col < 8; col++) {
                if (playerPieces.includes(board[row][col])) {
                    const isKing = board[row][col] === 2 || board[row][col] === 4;
                    const pieceColor = board[row][col] <= 2 ? "red" : "black";

                    let directions = [];
                    if (pieceColor === "red" || isKing) {
                        directions.push([1, -1], [1, 1]); // Down-left, down-right
                    }
                    if (pieceColor === "black" || isKing) {
                        directions.push([-1, -1], [-1, 1]); // Up-left, up-right
                    }

                    for (const [dx, dy] of directions) {
                        const jumpRow = row + dx * 2;
                        const jumpCol = col + dy * 2;
                        const midRow = row + dx;
                        const midCol = col + dy;

                        if (
                            jumpRow >= 0 && jumpRow < 8 &&
                            jumpCol >= 0 && jumpCol < 8 &&
                            board[jumpRow][jumpCol] === 0 &&
                            board[midRow][midCol] !== 0 &&
                            (board[midRow][midCol] <= 2 ? "red" : "black") !== pieceColor
                        ) {
                            hasJump = true;
                            break;
                        }
                    }

                    if (hasJump) break;
                }
            }
            if (hasJump) break;
        }

        return hasJump;
    };

    const getPlayableTiles = (board) => {
        let tiles = [];
        const playerPieces = playerColor === "red" ? [1, 2] : [3, 4];

        const forcedJump = hasJump(board, playerPieces);

        for (let row = 0; row < 8; row++) {
            for (let col = 0; col < 8; col++) {
                if (playerPieces.includes(board[row][col])) {
                    if (forcedJump) {
                        const isKing = board[row][col] === 2 || board[row][col] === 4;
                        const pieceColor = board[row][col] <= 2 ? "red" : "black";

                        let directions = [];
                        if (pieceColor === "red" || isKing) {
                            directions.push([1, -1], [1, 1]); // Down-left, down-right
                        }
                        if (pieceColor === "black" || isKing) {
                            directions.push([-1, -1], [-1, 1]); // Up-left, up-right
                        }

                        for (const [dx, dy] of directions) {
                            const jumpRow = row + dx * 2;
                            const jumpCol = col + dy * 2;
                            const midRow = row + dx;
                            const midCol = col + dy;

                            if (
                                jumpRow >= 0 && jumpRow < 8 &&
                                jumpCol >= 0 && jumpCol < 8 &&
                                board[jumpRow][jumpCol] === 0 &&
                                board[midRow][midCol] !== 0 &&
                                (board[midRow][midCol] <= 2 ? "red" : "black") !== pieceColor
                            ) {
                                tiles.push([row, col]);
                                break;
                            }
                        }
                    } else {
                        const isKing = board[row][col] === 2 || board[row][col] === 4;
                        const pieceColor = board[row][col] <= 2 ? "red" : "black";

                        let directions = [];
                        if (pieceColor === "red" || isKing) {
                            directions.push([1, -1], [1, 1]); // Down-left, down-right
                        }
                        if (pieceColor === "black" || isKing) {
                            directions.push([-1, -1], [-1, 1]); // Up-left, up-right
                        }

                        for (const [dx, dy] of directions) {
                            const newRow = row + dx;
                            const newCol = col + dy;

                            if (
                                newRow >= 0 && newRow < 8 &&
                                newCol >= 0 && newCol < 8 &&
                                board[newRow][newCol] === 0
                            ) {
                                tiles.push([row, col]);
                                break;
                            }
                        }
                    }
                }
            }
        }

        return tiles;
    };

    const getPossibleMoves = (row, col, board) => {
        const moves = [];
        const piece = board[row][col];
        const isKing = piece === 2 || piece === 4;
        const pieceColor = piece <= 2 ? "red" : "black";

        const jumpMoves = [];
        let directions = [];
        if (pieceColor === "red" || isKing) {
            directions.push([1, -1], [1, 1]); // Down-left, down-right
        }
        if (pieceColor === "black" || isKing) {
            directions.push([-1, -1], [-1, 1]); // Up-left, up-right
        }

        for (const [dx, dy] of directions) {
            const jumpRow = row + dx * 2;
            const jumpCol = col + dy * 2;
            const midRow = row + dx;
            const midCol = col + dy;

            if (
                jumpRow >= 0 && jumpRow < 8 &&
                jumpCol >= 0 && jumpCol < 8 &&
                board[jumpRow][jumpCol] === 0 &&
                board[midRow][midCol] !== 0 &&
                (board[midRow][midCol] <= 2 ? "red" : "black") !== pieceColor
            ) {
                jumpMoves.push([jumpRow, jumpCol]);
            }
        }

        if (jumpMoves.length > 0) {
            return jumpMoves;
        }

        for (const [dx, dy] of directions) {
            const newRow = row + dx;
            const newCol = col + dy;

            if (
                newRow >= 0 && newRow < 8 &&
                newCol >= 0 && newCol < 8 &&
                board[newRow][newCol] === 0
            ) {
                moves.push([newRow, newCol]);
            }
        }

        return moves;
    };

    const onTileClick = (row, col) => {
        if (winner) return;

        const isPlayable = playableTiles.some(([r, c]) => r === row && c === col);

        if (selectedTile && row === selectedTile[0] && col === selectedTile[1]) {
            setPossibleMoves([]);
            setSelectedTile(null);
        } else if (isPlayable) {
            setSelectedTile([row, col]);
            const moves = getPossibleMoves(row, col, board);
            setPossibleMoves(moves);
        } else if (selectedTile && possibleMoves.some(([r, c]) => r === row && c === col)) {
            setPlayableTiles([]);
            setPossibleMoves([]);

            const newBoard = board.map(row => [...row]);

            const [startRow, startCol] = selectedTile;
            const piece = board[startRow][startCol];
            const isKing = piece === 2 || piece === 4;

            newBoard[startRow][startCol] = 0;
            newBoard[row][col] = piece;

            if (!isKing) {
                if (piece === 1 && row === 7) {
                    newBoard[row][col] = 2; // Red to Red King
                } else if (piece === 3 && row === 0) {
                    newBoard[row][col] = 4; // Black to Black King
                }
            }

            const isJump = Math.abs(row - startRow) === 2;

            if (isJump) {
                const midRow = (row + startRow) / 2;
                const midCol = (col + startCol) / 2;
                newBoard[midRow][midCol] = 0;

                const furtherJumps = getPossibleMoves(row, col, newBoard)
                    .filter(([r, c]) => Math.abs(r - row) === 2);

                if (furtherJumps.length > 0) {
                    setBoard(newBoard);
                    setSelectedTile([row, col]);
                    setPossibleMoves(furtherJumps);
                    return;
                }
            }

            setBoard(newBoard);
            setSelectedTile(null);
            getMove(botColor, newBoard);
        } else {
            setSelectedTile(null);
            setPossibleMoves([]);
        }
    };

    useEffect(() => {
        if (playerColor === "red") {
            getMove(botColor, emptyBoard);
        } else {
            const tiles = getPlayableTiles(board);
            setPlayableTiles(tiles);
        }
    }, []);

    return (
        <div className="flex flex-col items-center justify-center p-4">
            {winner ? (
                <p className="text-onSurface p-2">{winner}</p>
            ) : (
                <p className="text-onSurface p-2">play me!</p>
            )}
            <div className="grid bg-amber-900 rounded-lg p-1.5">
                {board.map((row, rowIndex) => (
                    <div key={rowIndex} className="flex">
                        {row.map((cell, colIndex) => {
                            const isDark = (rowIndex + colIndex) % 2 === 1;
                            const isPlayableTile = playableTiles.some(
                                ([r, c]) => r === rowIndex && c === colIndex
                            );
                            const isPossibleMove = possibleMoves.some(
                                ([r, c]) => r === rowIndex && c === colIndex
                            );
                            const isSelected = selectedTile && selectedTile[0] === rowIndex && selectedTile[1] === colIndex;

                            return (
                                <div
                                    key={colIndex}
                                    style={{
                                        backgroundColor: isDark
                                            ? (isSelected ? "#4CAF50" : (isPlayableTile ? "#8BC34A" : (isPossibleMove ? "#2196F3" : "#9F7657")))
                                            : "#D6AE87"
                                    }}
                                    className={`w-9 h-9 flex items-center justify-center`}
                                    onClick={() => isDark ? onTileClick(rowIndex, colIndex) : null}
                                >
                                    {cell !== 0 && (
                                        <div
                                            style={{ backgroundColor: (cell === 1 || cell === 2) ? "#CA0918" : "black" }}
                                            className={`w-8 h-8 rounded-full flex items-center justify-center`}
                                        >
                                            {(cell === 2 || cell === 4) && (
                                                <svg
                                                    className="w-4 h-4 fill-current text-yellow-400"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};