import React from "react";
import { useNavigate } from "react-router-dom";
import { contestDetails } from "../Constants/Contests";
import { GamesInfo } from "../Constants/Games";
import { getContestStatus } from "../Constants/Contests";

const Contests = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {contestDetails.map((contest, index) => {
        const { status } = getContestStatus(contest.startDate, contest.endDate);
        return (
          <div
            key={index}
            onClick={() => navigate(contest.navigate)}
            className="flex items-center bg-white shadow-md rounded-md p-2 cursor-pointer transition-transform hover:scale-105 w-72"
          >
            <img
              className="w-16 h-16 object-cover rounded-md"
              src={contest.image}
              alt={contest.title}
            />
            <div className="ml-3 flex flex-col justify-center">
              <div className="text-sm font-semibold">{contest.title}</div>
              <div className="flex items-center mt-1">
                <img
                  className="w-6 h-6 rounded-md"
                  src={GamesInfo[contest.game].image}
                  alt="Game logo"
                />
                <span
                  className={`ml-2 text-xs font-semibold px-2 py-1 rounded 
                    ${
                      status === "Upcoming"
                        ? "bg-yellow-200 text-yellow-800"
                        : status === "Expired"
                        ? "bg-gray-200 text-gray-800"
                        : "bg-green-200 text-green-800"
                    }`}
                >
                  {status}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Contests;
