import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserLeaderboard } from "../API/User";
import { emojiMap } from "../Constants/Icons";
import Loading from "../Components/Loading";
import SearchBar from "../Components/SearchBar";
import Contests from "../Components/Contests";

const Leaderboard = () => {
  const navigate = useNavigate();

  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredUsers = topUsers.filter((user) =>
    ["username", "name", "company", "school"].some((key) =>
      user[key]
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
    )
  );

  const getTotalBots = (games) =>
    Object.values(games).reduce((total, ids) => total + ids.length, 0);

  useEffect(() => {
    getUserLeaderboard()
      .then((res) => {
        const leaderboardData = res.users.map((user, index) => ({
          ...user,
          rank: index + 1,
          totalBots: user.bots ? getTotalBots(user.bots) : 0,
        }));
        setTopUsers(leaderboardData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="pt-64">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 flex justify-center items-center">
      <div className="flex flex-col items-center justify-between p-4">
        <div className="flex flex-col gap-8 sm:w-full lg:max-w-screen-md md:max-w-screen-sm mt-4">
          <Contests />
          <SearchBar setSearchTerm={setSearchTerm} />
          <ul className="flex flex-col md:grid md:grid-cols-2 gap-4">
            {filteredUsers && filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <li
                  key={user.uid}
                  className={`flex items-center justify-between shadow-lg cursor-pointer w-full rounded-lg py-3 px-4 gap-3 hover:shadow-md hover:scale-[1.03] transition-all duration-200 ease-linear 
    ${
      user.rank === 1
        ? "bg-yellow-200"
        : user.rank === 2
        ? "bg-gray-200"
        : user.rank === 3
        ? "bg-orange-200"
        : "bg-white text-gray-700"
    }`}
                  onClick={() =>
                    navigate(`/profile/${user.username}`, {
                      state: { uid: user.uid },
                    })
                  }
                >
                  {/* Rank Badge */}
                  <div
                    className={`text-xl font-bold w-12 h-12 rounded-full flex items-center justify-center shrink-0
      ${
        user.rank === 1
          ? "bg-yellow-400 text-white"
          : user.rank === 2
          ? "bg-gray-400 text-white"
          : user.rank === 3
          ? "bg-orange-400 text-white"
          : "bg-gray-200 text-gray-700"
      }`}
                  >
                    {user.rank}
                  </div>

                  {/* User Info */}
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col w-full pr-2">
                      <span className="text-md break-all">
                        {user.name || "Anonymous User"}
                      </span>
                      <span className="text-sm break-all text-blue-500">
                        @{user.username}
                      </span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="font-semibold"> {user.rating}</span>
                      <span>{emojiMap[user.icon]}</span>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p className="text-lg text-onSurfaceSecond p-2">No users found</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
