import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  atomDark as dark,
  base16AteliersulphurpoolLight as light,
} from "react-syntax-highlighter/dist/esm/styles/prism";

// 1 = Red, 2 = Red King, 3 = Black, 4 = Black King

const initialBoardState = [
  [0, 1, 0, 1, 0, 1, 0, 1],
  [1, 0, 1, 0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0, 1, 0, 1],
  [0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0],
  [3, 0, 3, 0, 3, 0, 3, 0],
  [0, 3, 0, 3, 0, 3, 0, 3],
  [3, 0, 3, 0, 3, 0, 3, 0],
];

export const CheckersEmptyBoard = () => {
  return (
    <div className="grid bg-amber-900 rounded-lg p-1.5">
      {initialBoardState.map((row, rowIndex) => (
        <div key={rowIndex} className="flex">
          {row.map((cell, colIndex) => {
            const isDark = (rowIndex + colIndex) % 2 === 1;
            return (
              <div
                key={colIndex}
                style={{ backgroundColor: isDark ? "#9F7657" : "#D6AE87" }}
                className={`w-9 h-9 flex items-center justify-center`}
              >
                {cell !== 0 && (
                  <div
                    style={{
                      backgroundColor:
                        cell === 1 || cell === 2 ? "#CA0918" : "black",
                    }}
                    className={`w-8 h-8 rounded-full flex items-center justify-center`}
                  >
                    {(cell === 2 || cell === 4) && (
                      <svg
                        className="w-4 h-4 fill-current text-yellow-400"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export const CheckersVisualMap = {
  1: (
    <div className="w-8 h-8 rounded-full bg-red-500 flex items-center justify-center" />
  ),
  2: (
    <div className="w-8 h-8 rounded-full bg-red-500 flex items-center justify-center">
      <svg className="w-4 h-4 fill-current text-yellow-400" viewBox="0 0 20 20">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    </div>
  ),
  3: (
    <div className="w-8 h-8 rounded-full bg-black flex items-center justify-center" />
  ),
  4: (
    <div className="w-8 h-8 rounded-full bg-black flex items-center justify-center">
      <svg className="w-4 h-4 fill-current text-yellow-400" viewBox="0 0 20 20">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    </div>
  ),
};

export const CheckersDescription = `Checkers is a strategy game where players move diagonally to capture opponent pieces. 
King pieces (marked with crown) can move both forward and backward.`;

export const CheckersProblemStatement = () => {
  const gameStateExample = `gameState = [
    [0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0],
    [0, 1, 0, 2, 0, 1, 0, 1],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 3, 0, 0, 0, 0],
    [3, 0, 3, 0, 3, 0, 3, 0],
    [0, 3, 0, 3, 0, 3, 0, 3],
    [3, 0, 4, 0, 3, 0, 3, 0]
]`;

  const isDarkMode = document.documentElement.classList.contains("dark");

  return (
    <div className="space-y-4">
      <p>
        Implement a class with a makeMove() method that takes a board state and
        returns a valid move. The game state is an 8x8 grid where:
      </p>
      <ul className="list-disc pl-5">
        <li>0 = Empty square</li>
        <li>1 = Red piece</li>
        <li>2 = Red king</li>
        <li>3 = Black piece</li>
        <li>4 = Black king</li>
      </ul>

      <p>Example game state:</p>
      <SyntaxHighlighter language="python" style={isDarkMode ? dark : light}>
        {gameStateExample}
      </SyntaxHighlighter>

      <section className="pt-4 pb-4">
        <h2 className="text-lg font-bold">3rd party imports:</h2>
        <SyntaxHighlighter language="python" style={isDarkMode ? dark : light}>
          numpy, torch
        </SyntaxHighlighter>
      </section>

      <section>
        <h2 className="text-lg font-bold">Constraints:</h2>
        <ul className="list-disc pl-5">
          <li>Class instantiation time: 2 seconds</li>
          <li>Move calculation time: 2 seconds</li>
        </ul>
      </section>
    </div>
  );
};

export const CheckersStarterCode = `# See documentation of the draughts library
# https://github.com/miskibin/py-draughts/
import draughts
import random

# Fill in the makeMove function
class Player:

  def __init__(self, playerOne: bool):
    pass

  def makeMove(self, gameState: draughts.AmericanBoard) -> draughts.move:
    return random.choice([i for i in gameState.legal_moves])`;

export const CheckersGameCode = `# the checkers class that will be used to judge your code
class Checkers:
  def __init__(self):
    self.board = draughts.get_board(variant="american")
    self.turn = 1
    self.moves = []

  def legal_moves(game_state, turn):
    if isinstance(game_state, str):
      game_state = Game.decode_game_state(game_state)
    return [move for move in game_state.legal_moves]

  def detect_win(game_state, turn):
    if isinstance(game_state, str):
      game_state = draughts.Game(fen=game_state)

    if game_state.game_over:
      res = game_state.result
      if res == "1-0":
        return 1
      elif res == "0-1":
        return 2
      return 3
    
    return 0
  
  def make_move(game_state, turn, move):
    if isinstance(move, str):
      game_state.push_uci(move)
      return game_state
      
    if isinstance(game_state, str):
      game_state = Game.decode_game_state(game_state)

    if move not in [i for i in game_state.legal_moves]:
      return game_state
    
    game_state.push(move)
    return game_state

  def decode_game_state(encoded_game_state):
    return draughts.get_board(variant="american", fen=encoded_game_state)`;
