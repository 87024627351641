import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GamesInfo } from "../Constants/Games";
import SearchBar from "../Components/SearchBar";

export default function Home() {
  return (
    <div className="flex flex-col pt-24">
      <div className="w-full pb-12">
        <GameMenu />
      </div>
    </div>
  );
}

function GameMenu() {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredGames = Object.keys(GamesInfo).filter((game) =>
    game
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
  );
  return (
    <div className="flex flex-col items-center gap-12 px-4">
      <div className="flex flex-col max-w-full gap-8 sm:w-full lg:max-w-screen-md md:max-w-screen-sm">
        <SearchBar setSearchTerm={setSearchTerm} />

        <div className="grid justify-center items-center w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:justify-start">
          {filteredGames.length > 0 ? (
            filteredGames.map((game, index) => (
              <GameCard key={index} game={game} />
            ))
          ) : (
            <p className="text-lg text-onSurfaceSecond">No games found</p>
          )}
        </div>
      </div>
    </div>
  );
}

function GameCard({ game }) {
  const navigate = useNavigate();

  return (
    <div className="relative w-full max-w-sm rounded-xl shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 border overflow-hidden bg-white">
      {/* Game Image */}
      <div className="relative w-full">
        <img
          src={GamesInfo[game].image}
          alt={game}
          className="w-full h-48 object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-lg text-center py-2">
          {GamesInfo[game].title}
        </div>
      </div>

      {/* Card Footer */}
      <div className="flex flex-col items-center p-4 bg-gray-50">
        {GamesInfo[game].active ? (
          <div className="flex w-full justify-center items-center gap-2">
            <button
              onClick={() => navigate(`/editor/${game}`)}
              className="w-1/2 flex items-center justify-center bg-blue-800 text-white px-2 py-2 rounded-lg shadow hover:bg-gray-800 transition"
            >
              Code
            </button>

            <button
              onClick={() => navigate(`/arena/${game}`)}
              className="w-1/2 flex items-center justify-center bg-green-600 text-white px-2 py-2 rounded-lg shadow hover:bg-green-700 transition"
            >
              Battle
            </button>
          </div>
        ) : (
          <div className="text-gray-500 text-center py-2">Coming soon!</div>
        )}
      </div>
    </div>
  );
}
