import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { emojiMap } from "../Constants/Icons";
import { getGameBots } from "../API/Games";
import Loading from "../Components/Loading";
import { GamesInfo } from "../Constants/Games";
import SearchBar from "../Components/SearchBar";
import BattlePopup from "../BattleBots/BattlePopup";
import NotFound from "../Components/NotFound";
import Building from "../Components/Building";

const GameArena = ({ user }) => {
  const [bots, setBots] = useState([]);
  const [userBots, setUserBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bot1, setBot1] = useState(null);
  const [bot2, setBot2] = useState(null);
  const [showSelectBot1, setShowSelectBot1] = useState(false);
  const [showSelectBot2, setShowSelectBot2] = useState(false);

  const { game } = useParams();

  if (!(game.toLowerCase() in GamesInfo)) {
    return <NotFound />;
  }

  if (!GamesInfo[game.toLowerCase()].active) {
    return <Building />;
  }

  useEffect(() => {
    getGameBots(game)
      .then((res) => {
        const rankedBots = res.bots
          .sort((a, b) => b.rating - a.rating)
          .map((bot, index) => ({ ...bot, rank: index + 1 }));
        const filteredByUser = rankedBots.filter((bot) => bot.uid === user.uid);
        setBots(rankedBots);
        setUserBots(filteredByUser);
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const selectBot1 = (bot) => {
    setBot1(bot);
    setShowSelectBot1(false);
  };

  const selectBot2 = (bot) => {
    setBot2(bot);
    setShowSelectBot2(false);
  };

  if (loading) {
    return (
      <div className="pt-64">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 flex justify-center items-center overflow-hidden">
      <div className="flex flex-col items-center justify-between p-4">
        {error && (
          <div
            className="p-8 text-sm text-red-800 rounded-lg bg-red-50"
            role="alert"
          >
            <span className="font-medium">Error!</span> There was an issue
            getting the bots.
          </div>
        )}
        <div className="flex items-center justify-between">
          <div className="hidden lg:flex lg:flex-row w-full">
            <div className="flex-1">
              <Leaderboard
                bots={userBots}
                onSelect={selectBot1}
                title={"Your Bots"}
              />
            </div>

            <div className="flex-1">
              <GameDisplay
                bot1={bot1}
                bot2={bot2}
                game={game}
                user={user}
                setBot1={selectBot1}
                setBot2={selectBot2}
              />
            </div>

            <div className="flex-1">
              <Leaderboard
                bots={bots}
                onSelect={selectBot2}
                title={"All Bots"}
              />
            </div>
          </div>

          <div className="lg:hidden flex flex-col gap-4 p-4">
            <div className="flex-1">
              <GameDisplay
                bot1={bot1}
                bot2={bot2}
                game={game}
                user={user}
                setBot1={selectBot1}
                setBot2={selectBot2}
              />
            </div>

            <button
              className="p-4 bg-gray-300 rounded-lg"
              onClick={() => setShowSelectBot1(true)}
            >
              Select Your Bot
            </button>
            <button
              className="p-4 bg-gray-300 rounded-lg"
              onClick={() => setShowSelectBot2(true)}
            >
              Select Opponent Bot
            </button>
          </div>

          {showSelectBot1 && (
            <div className="pt-48">
              <Modal>
                <div className="relative pt-2">
                  <button
                    className="absolute top-2 right-2 text-xl flex items-center justify-center pt-2"
                    onClick={() => setShowSelectBot1(false)}
                  >
                    x
                  </button>
                  <Leaderboard
                    bots={userBots}
                    onSelect={selectBot1}
                    title={"Select Your Bot"}
                  />
                </div>
              </Modal>
            </div>
          )}

          {showSelectBot2 && (
            <div className="pt-48">
              <Modal>
                <div className="relative pt-2">
                  <button
                    className="absolute top-2 right-2 text-xl flex items-center justify-center pt-2"
                    onClick={() => setShowSelectBot2(false)}
                  >
                    x
                  </button>
                  <Leaderboard
                    bots={bots}
                    onSelect={selectBot2}
                    title={"Select Opponent Bot"}
                  />
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Modal = ({ children }) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-hidden">
      <div className="flex flex-col items-center justify-center bg-white rounded-lg h-3/4 overflow-y-scroll">
        <div className="">{children}</div>
      </div>
    </div>
  );
};

const GameDisplay = ({ bot1, bot2, game, user, setBot1, setBot2 }) => {
  const [showGame, setShowGame] = useState(false);

  const swapBots = () => {
    const temp1 = { ...bot1 };
    const temp2 = { ...bot2 };
    setBot1(temp2);
    setBot2(temp1);
  };

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-2xl font-bold text-center text-onSurface-light p-2">
        {GamesInfo[game].title}
      </h1>
      <div className="text-center">
        <span className="font-semibold text-blue-500">{bot1?.botname}</span>
        {(bot1 || bot2) && <span className="mx-2">vs</span>}
        <span className="font-semibold text-red-500">{bot2?.botname}</span>
      </div>
      {showGame && (
        <BattlePopup
          game={game}
          bot1={bot1}
          bot2={bot2}
          user={user}
          onClose={() => setShowGame(false)}
        />
      )}
      {!showGame && (
        <div className="flex justify-center items-center p-2">
          {GamesInfo[game].startBoard}
        </div>
      )}
      <div className="flex justify-center p-2">
        {bot1 && bot2 && !showGame ? (
          <div className="flex flex-col gap-2">
            <button
              className="px-4 py-2 mr-2 bg-gray-300 rounded"
              onClick={swapBots}
            >
              Swap Player Sides
            </button>
            <button
              className="px-4 py-2 mr-2 bg-gray-300 rounded"
              onClick={() => setShowGame(!showGame)}
            >
              Start Battle
            </button>
          </div>
        ) : (
          <p className="text-gray-500 text-center">
            {showGame ? "" : "Select bots to start a game!"}
          </p>
        )}
      </div>
    </div>
  );
};

const Leaderboard = ({ bots, onSelect, title }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredBots = bots.filter(
    (bot) =>
      bot.botname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      bot.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="md:p-4 p-8 flex flex-col gap-4 flex-1">
      <h2 className="text-xl text-center font-bold">{title}</h2>
      <div className="mx-4">
        <SearchBar placeholder="Search bots..." setSearchTerm={setSearchTerm} />
      </div>
      <ul className="rounded-md my-2 space-y-2 h-96 custom-scrollbar overflow-y-scroll overflow-x-hidden">
        {filteredBots.length > 0 ? (
          filteredBots.map((bot) => (
            <li
              key={bot.botId}
              onClick={() => onSelect(bot)}
              className="w-64 m-4 py-4 relative group flex flex-col rounded-md shadow-md transition-transform transform hover:scale-105 cursor-pointer bg-white"
            >
              <div className="text-3xl self-center mb-2"></div>

              <span className="font-semibold text-lg text-center truncate">
                {bot.botname}
              </span>

              <p className="text-xs text-gray-500 italic font-light text-center truncate">
                @{bot.username}
                {emojiMap[bot.usericon]}
              </p>

              <div className="text-gray-500 text-center text-base mt-1">
                {bot.rating}
              </div>

              <div className="hidden group-hover:flex flex-col items-center mt-3 rounded-lg transition-all duration-200">
                <button
                  className="text-sm text-green-500 hover:underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelect(bot);
                  }}
                >
                  Select to Battle
                </button>
                <button
                  className="text-sm text-blue-500 hover:underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/bots/${bot.game}/${bot.botId}`);
                  }}
                >
                  View Details
                </button>
              </div>
            </li>
          ))
        ) : (
          <div className="text-center text-gray-500">No bots found.</div>
        )}
      </ul>
    </div>
  );
};

export default GameArena;
