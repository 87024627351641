import React from "react";
import LoadingBrain from "../Images/Brains/loading.png";

function Loading() {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center">
      <img src={LoadingBrain} alt="Loading" className="animate-bounce h-40" />
      <p className="hidden md:flex text-center text-lg font-semibold text-onSurface">
        Loading, please wait...
      </p>
    </div>
  );
}

export default Loading;
