import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { emojiMap } from "../Constants/Icons";
import { getUserInfoByUsername, getUserInfoByUID } from "../API/User";
import Loading from "../Components/Loading";
import NotFound from "../Components/NotFound";
import UserBots from "../Components/UserBots";

const User = ({ user }) => {
  const navigate = useNavigate();
  const { username } = useParams();

  const state = useLocation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (state.state?.uid) {
      if (user && state.state.uid === user.uid) {
        navigate("/profile/");
      }
      getUserInfoByUID(state.state.uid)
        .then((res) => {
          setUserInfo(res);
        })
        .catch((error) => {
          setError(true);
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getUserInfoByUsername(username)
        .then((res) => {
          if (user && res.uid === user.uid) {
            navigate("/profile/");
          }
          setUserInfo(res);
        })
        .catch((error) => {
          setError(true);
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state, user]);

  if (loading) {
    return (
      <div className="pt-64">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="pt-16">
        <NotFound />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center pt-16">
      <div className="flex flex-col items-center w-full sm:w-full lg:max-w-screen-md md:max-w-screen-sm py-10">
        {/* Profile Header */}
        <div className="bg-white rounded-lg border-[1px] p-6 w-full max-w-4xl px-4">
          <div className="flex flex-col sm:flex-row items-center gap-6">
            <div className="w-24 h-24 bg-primary rounded-full flex items-center justify-center text-white text-6xl font-bold">
              {emojiMap[userInfo.icon]}
            </div>
            <div className="text-center sm:text-left">
              <h1 className="text-2xl font-semibold">
                {userInfo.name || "Anonymous User"}
              </h1>
              <p className="text-gray-500">@{userInfo.username}</p>
              <div className="flex flex-col gap-2 justify-between mt-2">
                <p className="text-sm text-gray-600">
                  Rating: <span className="font-bold">{userInfo.rating}</span>
                </p>
                <p className="text-sm text-gray-600">
                  School: {userInfo.school || "N/A"}
                </p>
                <p className="text-sm text-gray-600">
                  Company: {userInfo.company || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <UserBots bots={userInfo.bots} />
      </div>
    </div>
  );
};

export default User;
