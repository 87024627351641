import React from "react";
import {
  Connect4GameCode,
  Connect4GameView,
  Connect4StarterCode,
  Connect4Description,
  Connect4VisualMap,
  Connect4ProblemStatement,
  Connect4EmptyBoard,
} from "../Games/Connect4";
import { Connect4PlayView } from "../PlayBots/Connect4PlayView";
import { Connect4BattleView } from "../BattleBots/Connect4BattleView";
import {
  ChessGameView,
  ChessVisualMap,
  ChessDescription,
  ChessProblemStatement,
  ChessStarterCode,
  ChessGameCode,
  ChessEmptyBoard,
} from "../Games/Chess";
import { ChessPlayView } from "../PlayBots/ChessPlayView";
import { ChessBattleView } from "../BattleBots/ChessBattleView";
import {
  OthelloDescription,
  OthelloGameCode,
  OthelloProblemStatement,
  OthelloStarterCode,
  OthelloVisualMap,
  OthelloEmptyBoard,
} from "../Games/Othello";
import { OthelloPlayView } from "../PlayBots/OthelloPlayView";
import { OthelloBattleView } from "../BattleBots/OthelloBattleView";
import {
  TicTacToeDescription,
  TicTacToeEmptyBoard,
  TicTacToeGameCode,
  TicTacToeGameView,
  TicTacToeProblemStatement,
  TicTacToeStarterCode,
  TicTacToeVisualMap,
} from "../Games/TicTacToe";
import { TicTacToePlayView } from "../PlayBots/TicTacToePlayView";
import { TicTacToeBattleView } from "../BattleBots/TicTacToeBattleView";
import {
  CheckersDescription,
  CheckersEmptyBoard,
  CheckersGameCode,
  CheckersProblemStatement,
  CheckersStarterCode,
  CheckersVisualMap,
} from "../Games/Checkers";
import { CheckersPlayView } from "../PlayBots/CheckersPlayView";
import { CheckersBattleView } from "../BattleBots/CheckersBattleView";
import c4Img from "../Images/Game Gifs/c4.gif";
import chessImg from "../Images/Game Gifs/chess.gif";
import othelloImg from "../Images/Game Gifs/othello.gif";
import checkerImg from "../Images/Game Gifs/checkers.gif";
import tictactoeImg from "../Images/Game Gifs/tictactoe.gif";
import gomokuImg from "../Images/Game Gifs/gomoku.gif";

export const GamesInfo = {
  tictactoe: {
    title: "TicTacToe",
    description: "Get three in a row horizontally, vertically, or diagonally.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <TicTacToeBattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    gameView: (gameData, setShowWinner) => {
      return (
        <TicTacToeGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <TicTacToePlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMoveTime={setMoveTime}
          setMove={setMove}
        />
      );
    },
    startBoard: <TicTacToeEmptyBoard />,
    problemStatement: TicTacToeProblemStatement,
    visualMap: TicTacToeVisualMap,
    active: true,
    image: tictactoeImg,
    goal: TicTacToeDescription,
    gameCode: TicTacToeGameCode,
    starterCode: TicTacToeStarterCode,
  },
  connect4: {
    title: "Connect 4",
    description: "Create horizontal, vertical, or diagonal rows of four.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <Connect4BattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    gameView: (gameData, setShowWinner) => {
      return (
        <Connect4GameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <Connect4PlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMoveTime={setMoveTime}
          setMove={setMove}
        />
      );
    },
    startBoard: <Connect4EmptyBoard />,
    problemStatement: Connect4ProblemStatement,
    visualMap: Connect4VisualMap,
    active: true,
    image: c4Img,
    goal: Connect4Description,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  chess: {
    title: "Chess",
    description: "Checkmate your opponent in this complex strategy game.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <ChessBattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    gameView: (gameData, setShowWinner) => {
      return (
        <ChessGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <ChessPlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMove={setMove}
          setMoveTime={setMoveTime}
        />
      );
    },
    startBoard: <ChessEmptyBoard />,
    visualMap: ChessVisualMap,
    active: true,
    image: chessImg,
    goal: ChessDescription,
    problemStatement: ChessProblemStatement,
    gameCode: ChessGameCode,
    starterCode: ChessStarterCode,
  },
  othello: {
    title: "Othello",
    description:
      "Collect the most discs by flipping discs and trapping the opponent's pieces.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <OthelloBattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <OthelloPlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMove={setMove}
          setMoveTime={setMoveTime}
        />
      );
    },
    startBoard: <OthelloEmptyBoard />,
    problemStatement: OthelloProblemStatement,
    visualMap: OthelloVisualMap,
    active: true,
    image: othelloImg,
    goal: OthelloDescription,
    gameCode: OthelloGameCode,
    starterCode: OthelloStarterCode,
  },
  checkers: {
    title: "Checkers",
    description:
      "Elimate all opposing pieces by moving diagonally and jumping over opponents' pieces.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <CheckersBattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <CheckersPlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMove={setMove}
          setMoveTime={setMoveTime}
        />
      );
    },
    startBoard: <CheckersEmptyBoard />,
    problemStatement: CheckersProblemStatement,
    visualMap: CheckersVisualMap,
    active: true,
    image: checkerImg,
    goal: CheckersDescription,
    gameCode: CheckersGameCode,
    starterCode: CheckersStarterCode,
  },
  gomoku: {
    title: "Gomoku",
    description:
      "Place stones on a grid to surround more territory than the opponent.",
    gameView: (moves, gameData, setShowWinner) => {
      return (
        <Connect4GameView
          moves={moves}
          gameData={gameData}
          setShowWinner={setShowWinner}
        />
      );
    },
    visualMap: Connect4VisualMap,
    active: false,
    image: gomokuImg,
    goal: Connect4Description,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
};
