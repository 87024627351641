import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  atomDark as dark,
  base16AteliersulphurpoolLight as light,
} from "react-syntax-highlighter/dist/esm/styles/prism";

export const OthelloEmptyBoard = () => {
  const board = Array(8)
    .fill(0)
    .map(() => Array(8).fill(0));
  board[3][3] = 2;
  board[3][4] = 1;
  board[4][3] = 1;
  board[4][4] = 2;

  return (
    <div className="flex flex-col justify-center">
      <div className="flex justify-center items-center">
        <div className="grid w-fit border-4 border-black">
          {board.map((row, rowIndex) => (
            <div key={rowIndex} className="flex bg-green-500 w-fit">
              {row.map((cell, colIndex) => (
                <div
                  key={colIndex}
                  className="relative flex items-center justify-center p-1 border border-black"
                >
                  <div
                    className={`w-7 h-7 rounded-full transform transition-all duration-500 ease-in-out ${
                      cell === 1
                        ? "bg-black" // Player 1 (Black)
                        : cell === 2
                        ? "bg-white" // Player 2 (White)
                        : "" // Empty space
                    }`}
                  ></div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const OthelloDescription = `Flip your opponent's discs to dominate the board! Capture discs horizontally, vertically, or diagonally to turn them into your own color.`;

export const OthelloProblemStatement = () => {
  const isDarkMode = document.documentElement.classList.contains("dark");

  const gameStateExample = `gameState = [
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 2, 1, 0, 0, 0],
          [0, 0, 2, 2, 1, 0, 0, 0],
          [0, 0, 1, 1, 2, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0]
  ]`;

  const gameStateAfterMove = `gameState = [
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 2, 1, 0, 0, 0],
          [0, 0, 2, 2, 1, 0, 0, 0],
          [0, 0, 1, 1, 2, 0, 0, 0],
          [0, 0, 0, 1, 0, 0, 0, 0],
          [0, 0, 0, 1, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0]
  ]`;

  return (
    <div className="space-y-4">
      <p>
        You must implement a class with a method <code>makeMove()</code> that
        takes in a board and returns the best possible move for the current
        player.
      </p>
      <p>
        A <code>gameState</code> is represented as an 8x8 2D array of integers,
        where 1&apos;s represent player1&apos;s discs, 2&apos;s represent
        player2&apos;s discs, and 0&apos;s represent an empty space.
      </p>
      <p>Here is an example:</p>
      {isDarkMode ? (
        <div style={{ fontSize: "16px" }}>
          <SyntaxHighlighter
            language="python"
            style={isDarkMode ? dark : light}
          >
            {gameStateExample}
          </SyntaxHighlighter>
        </div>
      ) : (
        <SyntaxHighlighter language="python">
          {gameStateExample}
        </SyntaxHighlighter>
      )}
      <p>After player1 places a disc, the gameState updates as follows:</p>
      {isDarkMode ? (
        <div style={{ fontSize: "16px" }}>
          <SyntaxHighlighter
            language="python"
            style={isDarkMode ? dark : light}
          >
            {gameStateAfterMove}
          </SyntaxHighlighter>
        </div>
      ) : (
        <SyntaxHighlighter language="python">
          {gameStateAfterMove}
        </SyntaxHighlighter>
      )}
      <p>
        Your <code>makeMove()</code> function should return the row and column
        where player1 will place their disc.
      </p>
      <section className="pt-4 pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          3rd party imports:
        </h2>
        <div className="whitespace-pre-wrap">
          {isDarkMode ? (
            <div style={{ fontSize: "16px" }}>
              <SyntaxHighlighter
                language="python"
                style={isDarkMode ? dark : light}
              >
                numpy, torch
              </SyntaxHighlighter>
            </div>
          ) : (
            <SyntaxHighlighter language="python">
              numpy, torch
            </SyntaxHighlighter>
          )}
        </div>
      </section>
      <section className="pb-4">
        <h2 className="text-lg font-bold dark:text-white text-black">
          Constraints:
        </h2>
        <div className="whitespace-pre-wrap">
          <ul className="pl-5 list-disc">
            <li>Time to instantiate class: 2 seconds</li>
            <li>Time per move: 2 seconds</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export const OthelloVisualMap = {
  1: (
    <div
      className={`w-8 h-8 border-2 rounded-full transform transition-all duration-500 ease-in-out bg-white`}
    ></div>
  ),
  2: (
    <div
      className={`w-8 h-8 border-2 rounded-full transform transition-all duration-500 ease-in-out bg-black`}
    ></div>
  ),
};

export const OthelloStarterCode = `import random
from game import Game

# Random bot
# Update the makeMove function
class Player:
  def __init__(self, player):
    self.player = player

  def makeMove(self, gameState: list[list[int]]) -> list[int]:
    return random.choice(Game.legal_moves(gameState, self.player))
`;

export const OthelloGameCode = `# the othello class that will be used to judge your code

class Othello:
    def __init__(self):
        self.board = [[0] * 8 for _ in range(8)]
        self.board[3][3] = self.board[4][4] = 1  # White
        self.board[3][4] = self.board[4][3] = 2  # Black
        self.turn = 2  # Black goes first
        self.moves = []
    
    def is_valid_move(self, row, col):
        if row < 0 or row > 7 or col < 0 or col > 7 or self.board[row][col] != 0:
            return False
        
        directions = [(0,1), (1,0), (-1,0), (0,-1), (1,1), (-1,-1), (1,-1), (-1,1)]
        opponent = 1 if self.turn == 2 else 2
        
        for dx, dy in directions:
            x, y = row + dx, col + dy
            if 0 <= x < 8 and 0 <= y < 8 and self.board[x][y] == opponent:
                x, y = x + dx, y + dy
                while 0 <= x < 8 and 0 <= y < 8:
                    if self.board[x][y] == 0:
                        break
                    if self.board[x][y] == self.turn:
                        return True
                    x, y = x + dx, y + dy
        return False
    
    def get_valid_moves(self):
        valid_moves = []
        for row in range(8):
            for col in range(8):
                if self.is_valid_move(row, col):
                    valid_moves.append((row, col))
        return valid_moves
    
    def make_move(self, row, col):
        if not self.is_valid_move(row, col):
            return False
        
        directions = [(0,1), (1,0), (-1,0), (0,-1), (1,1), (-1,-1), (1,-1), (-1,1)]
        opponent = 1 if self.turn == 2 else 2
        
        self.board[row][col] = self.turn
        pieces_to_flip = []
        
        for dx, dy in directions:
            x, y = row + dx, col + dy
            temp_flip = []
            while 0 <= x < 8 and 0 <= y < 8 and self.board[x][y] == opponent:
                temp_flip.append((x, y))
                x, y = x + dx, y + dy
                if 0 <= x < 8 and 0 <= y < 8 and self.board[x][y] == self.turn:
                    pieces_to_flip.extend(temp_flip)
                    break
        
        for x, y in pieces_to_flip:
            self.board[x][y] = self.turn
        
        self.turn = opponent
        
        # Skip turn if no valid moves for next player
        if not self.get_valid_moves():
            self.turn = self.turn % 2 + 1
        
        return True
    
    def detect_win(self):
        if self.get_valid_moves():
            return 0  # Game not over
        
        self.turn = self.turn % 2 + 1
        if self.get_valid_moves():
            self.turn = self.turn % 2 + 1
            return 0  # Game not over
        
        # Count pieces
        white_count = sum(row.count(1) for row in self.board)
        black_count = sum(row.count(2) for row in self.board)
        
        if white_count > black_count:
            return 1  # White wins
        elif black_count > white_count:
            return 2  # Black wins
        else:
            return 3  # Tie
`;
