import React, { useState, useEffect, useRef, useCallback } from "react";
import Chessboard from "chessboardjsx";
import { Chess } from "chess.js";

export const ChessBattleView = ({
  player1,
  player2,
  user,
  setShowWinner,
  setWinner,
  setInvalid,
  setTimeoutFlag,
  setError,
}) => {
  const [moves, setMoves] = useState([]);
  const [game] = useState(new Chess());
  const [position, setPosition] = useState(game.fen());
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [gameEnd, setGameEnd] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [winnerFound, setWinnerFound] = useState(false);

  const socketRef = useRef(null);
  const intervalRef = useRef(null);
  const movesRef = useRef(moves);
  const currentMoveIndexRef = useRef(currentMoveIndex);
  const winnerFoundRef = useRef(winnerFound);

  useEffect(() => {
    movesRef.current = moves;
  }, [moves]);
  useEffect(() => {
    currentMoveIndexRef.current = currentMoveIndex;
  }, [currentMoveIndex]);
  useEffect(() => {
    winnerFoundRef.current = winnerFound;
  }, [winnerFound]);

  const initializeWebSocket = useCallback(async () => {
    const token = await user.getIdToken();
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_endpointURL}/game/match`
    );

    socketRef.current.onopen = () => {
      socketRef.current.send(
        JSON.stringify({
          user: token,
          "bot1-ID": player1.botId,
          "bot2-ID": player2.botId,
          game: "chess",
        })
      );
    };

    socketRef.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.winner) {
          setWinner(data.winner);
          setWinnerFound(true);
        }
        if (data.move && !data.invalid) {
          setMoves((prev) => [...prev, data.move]);
        }
        if (data.exception) setError(true);
        if (data.invalid) setInvalid(true);
        if (data.timeout) setTimeoutFlag(true);
      } catch (err) {
        console.error("Error parsing message:", err);
      }
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socketRef.current.onclose = () => {
      console.log("Connection closed");
    };
  }, [user]);

  const stepForward = useCallback(() => {
    if (currentMoveIndexRef.current < movesRef.current.length) {
      if (
        winnerFoundRef.current &&
        currentMoveIndexRef.current === movesRef.current.length - 1
      ) {
        setGameEnd(true);
      } else {
        const move = movesRef.current[currentMoveIndexRef.current];
        game.move({
          from: move.slice(0, 2),
          to: move.slice(2, 4),
          promotion: move.length > 4 ? move.slice(4, 5) : "q",
        });
        setPosition(game.fen());

        const newIndex = currentMoveIndexRef.current + 1;
        setCurrentMoveIndex(newIndex);
      }
    }
  }, [game]);

  const stepBackward = useCallback(() => {
    if (currentMoveIndexRef.current > 0) {
      game.undo();
      setPosition(game.fen());
      setCurrentMoveIndex((prev) => prev - 1);
      setGameEnd(false);
    }
  }, [game]);

  const handleRestart = useCallback(() => {
    game.reset();
    setPosition(game.fen());
    setCurrentMoveIndex(0);
    setIsPlaying(true);
    setGameEnd(false);
  }, [game]);

  useEffect(() => {
    if (isPlaying && !gameEnd) {
      intervalRef.current = setInterval(() => {
        if (currentMoveIndexRef.current < movesRef.current.length) {
          stepForward();
        }
      }, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPlaying]);

  useEffect(() => {
    if (gameEnd) {
      setIsPlaying(false);
      setShowWinner(true);
      clearInterval(intervalRef.current);
    } else {
      setShowWinner(false);
    }
  }, [gameEnd]);

  useEffect(() => {
    initializeWebSocket();
    return () => {
      if (socketRef.current) {
        console.log("Closing connection");
        socketRef.current.close();
      }
    };
  }, [initializeWebSocket]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-center">
        <Chessboard
          width={350}
          position={position}
          boardStyle={{
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
          }}
        />
      </div>
      <div className="flex flex-row justify-center gap-2 p-2 pt-4">
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={stepBackward}
          disabled={isPlaying || currentMoveIndex === 0}
        >
          Previous
        </button>
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={stepForward}
          disabled={isPlaying || gameEnd}
        >
          Next
        </button>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
          onClick={() => setIsPlaying(!isPlaying)}
          disabled={gameEnd}
        >
          {isPlaying ? "Pause" : "Play"}
        </button>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded"
          onClick={handleRestart}
        >
          Restart
        </button>
      </div>
    </div>
  );
};
